import PropTypes from 'prop-types'
import React from 'react'

import pic01 from '../images/pic01.jpg'
import pic02 from '../images/pic02.jpg'
import pic03 from '../images/pic03.jpg'

import emailjs from 'emailjs-com'

class Main extends React.Component {
  constructor() {
    super()

    this.inputFromName = React.createRef()
    this.inputReplyTo = React.createRef()
    this.inputMessage = React.createRef()

    this.state = {
      successMessage: '',
      errorMessage: '',
    }
  }

  handleFormSubmit(e) {
    e.preventDefault()

    const serviceId = 'default_service'
    const templateId = 'template_OhXwLH3T'
    const userId = 'user_5Mt2hWnZeWtqvo0QPSJj3'

    const data = {
      from_name: this.inputFromName.current.value,
      reply_to: this.inputReplyTo.current.value,
      message: this.inputMessage.current.value,
    }

    if (!data.from_name || !data.reply_to || !this.inputMessage) {
      this.setState({
        successMessage: '',
        errorMessage: 'Please fill out all required fields.',
      })

      return false
    }

    emailjs.send(serviceId, templateId, data, userId)

    this.setState({
      successMessage: 'Message sent.',
      errorMessage: '',
    })
  }

  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      ></div>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="intro"
          className={`${this.props.article === 'intro' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Intro</h2>
          <span className="image main">
            <img src={pic01} alt="g5C " />
          </span>
          <p>
            Wir verstehen uns nicht als reine Entwickler, sondern als Anbieter
            qualitativer Lösungen und Dienste, die einen entscheidenden Anteil
            am Erfolg Ihres Unternehmens haben.
          </p>
          <p>
            Dabei wollen wir gemeinsam mit Ihnen erfolgreich seien und sind
            bereit in Sie zu investieren. Wir sind Ihr Ansprechpartner in den
            Bereichen Software, Internet, Design und Marketing - von der
            Konzeption, über die Wirtschaftlichkeit und Ausgestaltung, bis hin
            zur praktischen Umsetzung.
          </p>
          <p>
            Umfassende und pro-aktive Betreuung, auch nach der Inbetriebnahme,
            ist für uns selbstverständlich. Wir setzen auf langfristige
            Geschäftsbeziehungen, daher ist wirkungsvoller und leistungsstarker
            Support ein zentraler Aspekt unserer Dienstleistungen.
          </p>
          {close}
        </article>

        <article
          id="work"
          className={`${this.props.article === 'work' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Apps</h2>
          <span className="image main">
            <img src={pic02} alt="Apps von g5C" />
          </span>
          <p>
            Eine kleine Auswahl unserer Desktop-, Mobile- und Web-Applikationen.
          </p>
          <p>
            <a
              href="https://mame.app"
              rel="noopener noreferrer"
              target="_blank"
            >
              Mame Website Builder
            </a>{' '}
            - Create ultra fast websites.
            <br />
            Free Drag &amp; Drop Website builder focused on Performance, SEO and
            accessibility.
          </p>
          <p>
            <a
              href="https://empoche.com"
              rel="noopener noreferrer"
              target="_blank"
            >
              Empoche.com
            </a>{' '}
            - Take back Control of your time.
            <br />
            Free Task and Time Management for Linux, MacOS and Windows.
          </p>
          <p>
            <a
              href="https://nowpass.org"
              rel="noopener noreferrer"
              target="_blank"
            >
              Nowpass.org
            </a>{' '}
            - Open Source Password Management.
            <br />
            Browser Extensions for Google Chrome and Mozilla Firefox.
          </p>
          <p>
            <a
              href="https://compojoom.com"
              rel="noopener noreferrer"
              target="_blank"
            >
              Matukio
            </a>{' '}
            - Event Management Software.
            <br />
            One of the most popular event management solutions for Joomla.
          </p>
          <p>
            <a
              href="https://compojoom.com"
              rel="noopener noreferrer"
              target="_blank"
            >
              cForms
            </a>{' '}
            - Form Management.
            <br />
            Modern Angular based form management for WordPress and Joomla.
          </p>
          {close}
        </article>

        <article
          id="about"
          className={`${this.props.article === 'about' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Über uns</h2>
          <span className="image main">
            <img src={pic03} alt="" />
          </span>
          <p>
            Seit vielen Jahren erstellen wir ausgereifte und professionelle
            Software- und Web- Lösungen. Dabei wollen wir mehr sein als nur eine
            Entwickler-Schmiede, wir sind ein Incubator für Ihr Business. Von
            Planung, Marketing bis hin zur technischen Umsetzung sind wir an
            Ihrer Seite.
          </p>
          <p>
            Unser Ziel ist es Lösungen zu bieten die Ihnen und Ihren Kunden
            einen direkten Mehrwert bieten und Ihre Investitionen in kürzester
            Zeit wieder einspielen.
          </p>
          <p>
            Dabei haben wir nicht nur die Technik im Blick, sondern auch andere
            entscheidende Elemente wie Prozesse, Rechtssicherheit, Usability,
            ansprechendes Design und Marketing. Wir arbeiten erfolgsorientiert
            nach modernen Methoden aus der agilen Software-Entwicklung. Dadurch
            können Sie sich auf eine fristgerechte Umsetzung zu dem vereinbarten
            Budget verlassen.
          </p>
          {close}
        </article>

        <article
          id="contact"
          className={`${this.props.article === 'contact' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Kontakt</h2>

          <form>
            <div className="field half first">
              <strong>g5C</strong>
              <br />
              Yves Gerd Hoppe
              <br />
              Libellenstr. 27
              <br />
              80399 München
              <br />
            </div>

            <div className="field half">
              0151 / 111 0 3000
              <br />
              <a href="mailto:contact@g5c.de">contact@g5c.de</a>
              <br />
              <br />
              DE282617893
              <br />
            </div>

            <div className="field">
              <strong>Bildquellen</strong>
              <br />
              <a
                href="https://fotolia.de"
                rel="noopener noreferrer nofollow"
                target="_blank"
              >
                fotolia.de
              </a>
            </div>

            <div className="12u field">
              {this.state.successMessage}
              {this.state.errorMessage}
            </div>

            <div className="field half first">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                name="name"
                id="name"
                required="true"
                ref={this.inputFromName}
              />
            </div>
            <div className="field half">
              <label htmlFor="email">Email</label>
              <input
                type="text"
                name="email"
                id="email"
                required="true"
                ref={this.inputReplyTo}
              />
            </div>
            <div className="field">
              <label htmlFor="message">Nachricht</label>
              <textarea
                name="message"
                id="message"
                rows="4"
                required="true"
                ref={this.inputMessage}
              ></textarea>
            </div>
            <ul className="actions">
              <li>
                <input
                  type="submit"
                  value="Nachricht senden"
                  className="special"
                  onClick={e => this.handleFormSubmit(e)}
                />
              </li>
            </ul>
          </form>
          <ul className="icons">
            <li>
              <a
                href="https://twitter.com/g5c_de"
                className="icon fa-twitter"
                rel="noopener noreferrer"
                target="_blank"
              >
                <span className="label">Twitter</span>
              </a>
            </li>
            <li>
              <a
                href="https://www.facebook.com/G5C-572697786145982/"
                className="icon fa-facebook"
                rel="noopener noreferrer"
                target="_blank"
              >
                <span className="label">Facebook</span>
              </a>
            </li>
            <li>
              <a
                href="https://github.com/yvesh"
                className="icon fa-github"
                rel="noopener noreferrer"
                target="_blank"
              >
                <span className="label">GitHub</span>
              </a>
            </li>
          </ul>
          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
